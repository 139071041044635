import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout/layout"
import PhotoGrid from "../components/photo-grid/photo-grid.js"
import { GlobalHead } from "../helpers/helpers"

const Images = () => {
    const data = useStaticQuery(graphql`
        query CloudinaryImagesHome {
            allCloudinaryMedia(
                filter: { public_id: { regex: "/^rm-photos?/homepage?//" } }
                sort: { public_id: ASC }
            ) {
                edges {
                    node {
                        public_id
                        secure_url
                        context {
                            custom {
                                alt
                            }
                        }
                    }
                }
            }
        }
    `)

    return <PhotoGrid data={data} />
}

export function Head() {
    return <GlobalHead></GlobalHead>
}

export default function Home() {
    return <Layout>{Images()}</Layout>
}

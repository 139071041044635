import React from "react"
import PropTypes from "prop-types"
import "./photo-grid.scss"
import { LazyLoadImage } from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/opacity.css"
import { getAltText, addCloudinaryAttributionFlag } from "../../helpers/helpers"

function PhotoGrid({ data }) {
    const images = data.allCloudinaryMedia.edges
    const imageUrls = []
    images.map(image =>
        imageUrls.push(addCloudinaryAttributionFlag(image.node.secure_url)),
    )

    return (
        <>
            <div className="photo-grid">
                {images.map((image, index) => (
                    <div
                        className="image__container"
                        key={`${index}-image-container`}
                    >
                        <LazyLoadImage
                            alt={getAltText(image.node.context)}
                            src={addCloudinaryAttributionFlag(
                                image.node.secure_url,
                            )}
                            className="image"
                            effect="opacity"
                            wrapperClassName={`photo-grid__item photo-grid__item-${index}`}
                            key={`${index}-image`}
                        />
                    </div>
                ))}
            </div>
        </>
    )
}

PhotoGrid.propTypes = {
    data: PropTypes.object.isRequired,
}

export default PhotoGrid
